<template>
    <div>
      <div id="container"></div>
      <CCard class="c-card">
        <CCardBody>
          <CCardTitle class="c-card-title">Pricing Plans</CCardTitle>
  
          <CRow>
            <CCol sm="6">
              <CCard>
                <CCardBody class="card-plan">
                  <CCardTitle class="card-plan-title">Your Details</CCardTitle>
                  <CSelect
                    label="Country"
                    vertical
                    :options="country"
                    :value="country"
                    class="custom-select-option"
                  >
                  </CSelect>
                  <CSelect
                    label="Payment method"
                    vertical
                    :options="paymentMethod"
                    :value="paymentMethod"
                    class="custom-select-option"
                  >
                  </CSelect>
                  <!-- <CustomSelect
                    :options="['go', 'python', 'rust', 'javascript']"
                    :default="'go'"
                    class="select"
                    @input="alert(displayToKey($event))"
                  /> -->
                </CCardBody>
              </CCard>
            </CCol>
            <CCol sm="6">
              <CCard>
                <CCardBody class="card-plan">
                  <CCardTitle class="card-plan-title">Hunson Abadeer</CCardTitle>
                  <div class="card-plan-subs">
                    <h1 class="card-plan-subs-heading">$500.00</h1>
                    <p class="card-plan-subs-p">Per Year</p>
                  </div>
                  <CForm class="card-plan-pricing-check">
                    <h5>Pricing Plans</h5>
                    <CInputCheckbox class="card-plan-checkbox" checked label="Standard (Per Usage)"/>
                    <CInputCheckbox class="card-plan-checkbox" label="Annual (Per Year)"/>
                  </CForm>
                  <div class="card-plan-cta">
                    <button class="card-plan-btn black--" @click="showType()" >Upgrade Plan</button>
                  </div>
  
                  <div class="due-date">
                    <div class="due-date-title">Due Date</div>
                    <div>
                      <span class="due-date-info">Plan Ends at</span>
                      <span class="due-date-date"> 9th Jun 2024</span>
                    </div>
                  </div>
  
                  <CButton class="cancel-subs" color="link">Cancel Subscription</CButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </template>
  
  <script>
  import Konva from 'konva';

  // import CustomSelect from '../../components/CustomSelect.vue';
    export default {
      name: 'PricingPlan',
      components: {
        // CustomSelect
      },
      data: function() {
        return {
          "plans": [
            {
              "title": "Plan 1",
              "type": "Standard",
              "billingPeriod": "Per Usage",
              "price": "$100",
              "subscription": "Subscription by Day (24 hours).",
              "edit": "User can edit freely within the requested hours.",
              "accountDeactivation": "Account will be auto deactivated by the system once the grace period ends.",
              "gracePeriod": "A grace period of 30 Days is given for the user to renew their subscription after the previously subscribed plan expired.",
              "currentPlan": true,
            },
            {
              "title": "Plan 2",
              "type": "Annual",
              "billingPeriod": "Per Year",
              "price": "$500",
              "subscription": "Subscription by Year (365 days).",
              "edit": "User can edit freely throughout the year.",
              "accountDeactivation": "Account will be auto deactivated by the system once the grace period ends.",
              "gracePeriod": "A grace period of 30 Days is given for the user to renew their subscription after the previously subscribed plan expired.",
              "currentPlan": false,
            }
          ],
          country: [
            "Malaysia",
            "Indonesia",
            "Thailand",
            "Singapore"
          ],
          paymentMethod: [
            {
              label: "ending 0434",
              value: "mastercard"
            },
            {
              label: "ending 1434",
              value: "visa"
            }
          ],
          purchasePlansIsShown: true
        }
      },
      methods: {
        showPricePlan(type) {
          console.log(type)
          this.purchasePlansIsShown = !this.purchasePlansIsShown;
        },
        showType(){
          console.log(this.$route.params.id)
        }
      },
  }
  </script>
  
<style>
.c-card {
    max-width: 59rem;
    width: 100%;
    margin: 0 auto;
}
  
.card-plan {
    padding: 32px;
}
  
.c-card-title {
    margin-bottom: 40px;
}
  
.card-plan-title {
    color: #434343;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
}
  
.card-plan-btn {
    padding: 10px;
    border-radius: 32px;
    background: #fff;
    border: 1px solid #0A1439;
    width: 100%;
    max-width: 247px;
    margin: 0 auto;
    display: block;
    font-size: 15px;
}
  
  /* .card-plan-btn.blue-gradient {
    background-image: linear-gradient(90deg, #252AE9 0%, rgba(14, 124, 136, 0.50) 100%);
    color: #fff;
    font-weight: 700;
  } */
  
.card-plan-btn.black-- {
    border: 1px solid #0A1439;
    background:#0A1439;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    transition: 0.4s ease;
}

.card-plan-btn.black--:hover {
  border: 1px solid #0A1439;
  background:#fff;
  color: #0A1439;
}
  
.custom-select-option {
    color: #0A1439;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
}
  
.custom-select-option select {
    border-radius: 35px;
    height: auto;
    padding: 18px 32px;
    font-size: 16px;
    font-weight: inherit;
    line-height: 23px;
    letter-spacing: 0.1px;
}
  
.card-plan-subs {
    margin: 40px 0;
}
  
.card-plan-subs-heading {
    color: #0A1439;
    font-size: 48px;
    font-weight: 700;
    line-height: 40px;
}
  
.card-plan-subs-p {
    color: #374754;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
}
  
.card-plan-pricing-check h5 {
    color: #0A1439;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
}
  
.card-plan-checkbox {
    color: #0A1439;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}
  
.card-plan-cta {
    margin-block: 24px 40px;
}
  
.due-date {
    margin-bottom: 40px;
}
  
.due-date-title {
    color: #0A1439;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
}
  
.due-date-info {
    color: #8C8C8C;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}
  
.due-date-date {
    color: #0A1439;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}
  
.cancel-subs {
    color: #FF4D4F;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}
  
.cancel-subs:hover {
    color: rgb(255 77 79 / 70%);
}
  
  </style>
  